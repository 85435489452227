
:root {
    --font-weight-regular: 400;
    --font-weight-heading: 500;

    /* Dark theme */
    --bg1 : rgb(17, 27, 33) ;
    --bg2 : rgb(32, 44, 51);
    --fg1 : rgb(233, 237, 239) ;
    --fg2 : rgb(209, 215, 240) ;

    --header-size-primary: 1.063rem;
    --header-size-secondary: 1rem;
    --text-size-primary: 0.875rem;
    --text-size-secondary: 0.888rem;
    --main-spacing: 0.5rem;
    --main-transition : .3s ;
}


*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body{
     font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    font-weight: var(--font-weight-regular);
    font-size: var(--text-size-primary);

    background: var(--bg1);
    color: var(--fg1);
}


.dashboard{
    width: 100%;
    max-width: 1500px;
    height: 90vh;
    overflow: hidden;

    display: flex;
    justify-content: space-between;
    align-items: center;

    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
}

@media (min-width:576px) {
    .dashboard {
        height: 98%;
    }
}

.left-pane, .right-pane{
    height: 100%;
}

.left-pane{
    width: 30%;
    height: 100%;
    position: relative;
    border-right: 1px solid #444;
    user-select: none;
}

@media (max-width: 576px) {
    .right-pane{
        display: none;
    }
    .left-pane {
        width: 100%;
    }
}

@media (min-width:320px) {
    .left-pane{
        min-width: 320px;
    }
}

.right-pane{
    width: 70%;
}

/* ------------------ LEFT PANE ------------------------ */
.left-pane-top-nav{
    width: 100%;
    height: 60px;
    max-height: 60px;
    padding: 1em 2em !important;
    background-color: var(--bg2);

    display: flex;
    justify-content: space-between;
    align-items: center;
}

.left-pane-top-nav, .left-pane-search, .left-pane-note-list-primary-container, .left-pane-bottom-nav{
    width: 100%;
    padding: .5em 1em .5em;
}

.left-pane-search{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.left-pane-note-list-primary-container{
 height: 77%;
 overflow: scroll;
}

.left-pane-note-list-primary-container::-webkit-scrollbar{
    display: none;
}

.left-pane-bottom-nav{
    position: absolute;
    bottom: 0;
    right: 0;
    height: 60px;
    padding: 0 !important;

    display: flex;
    justify-content: space-between;
    align-items: center;
}

/*.left-pane-top-nav */
.left-pane-top-nav>div{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.menu, .logo, .user{
    cursor: pointer;
}

.menu{
    font-size: 1rem;
    margin-right: 1em;

    position: relative;
}
.menu .menu-drop-down{
    max-height: 0;
    overflow: hidden;
    position: absolute;
    top: 100%;
    left: -1.75em;
    z-index: 5;
    background: var(--bg2);
    width: 13rem;
    transition: max-height linear var(--main-transition);
}
.menu:hover .menu-drop-down{
    max-height: 400px;
}

.menu .menu-drop-down p{
    padding: 1.2em 1.5em;
    border-top-right-radius: 50px;
    border-bottom-right-radius: 50px;
}
.menu .menu-drop-down p:hover{
    background-color: var(--bg1);
}


.logo{
    font-weight: var(--font-weight-heading);
    font-size: 1.2rem;
}
.user{
    position: relative;
    text-align: right;
    font-size: 1rem;
}
.user .user-drop-down{
    height: 0;
    overflow: hidden;
    position: absolute;
    left: -65px;
    top: 100%;
    z-index: 5;

    background-color: var(--bg2);
    text-align: left !important;
    width: 120px ;
    transition: var(--main-transition);
}
.user:hover .user-drop-down{
    height: 120px;
}
.user .user-drop-down p{
    padding: 1em .7em;
}
.user .user-drop-down p:hover{
    background-color: var(--bg1);
}
.user .user-drop-down .logout:hover{
    background-color: rgb(200, 0,0);
}

.user-profile-pane{
    width: 100%;
    height: 100%;
    border-right: thin solid var(--fg2);
    background-color: var(--bg1);

    position: absolute;
    right: 0;
    top: 0;
    z-index: 10;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    row-gap: 4em;

}
.user-profile-pane .profile{
    font-weight: 600;
    font-size: 1.2rem;
    width: 100%;
    height: 60px;
    max-height: 110px;
    background-color: var(--bg2);
    padding: 1em 1em .3em;

    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    column-gap:1.5em;
}
.cancel-profile-icon:hover{
    transition: var(--main-transition);
    color: rgb(223, 51, 51);
}
.profile-photo{
    width: 45%;
    background-color: var(--bg2);
    padding: 1.5em;
    border-radius: 100%;
}
@media (max-width:768){
    .profile-photo{
        width: ;
    }
}
.profile-photo img{
    width: 100%;
}

.form-container{
    width: 100%;
}
.user-profile-form{
    width: 100%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 2em;
}
.user-profile-form>div{
    width: 100%;
    padding: 0 1em;
    position: relative;
}
.user-profile-form label{
    font-weight: var(--font-weight-heading);
}
.user-profile-form input{
    font-weight: var(--font-weight-regular);
    font-size: 1.1rem;
    width: 100%;
    height: 40px;
    color: var(--fg2);
    padding: 0 1em;
    border: none;
    background-color: transparent;
    border-bottom: 2px solid var(--bg2); 
}
.user-profile-form .update-profile-btn{
    font-size: 1.2rem;
    background-color: transparent;
    border: thin solid var(--fg2);
    color: var(--fg2);
    width: 90%;
    max-width: 300px;
    height: 60px;
}
.user-profile-form>div>span{
    position: absolute;
    right: 1em;
    top: 1.5em;
    color: #666;
    font-size: 1.2rem;
}
/* ========================END OF USER PROFILE STYLING================= */


/* .left-pane-search */
.left-pane-search .search-container{
    width: calc(100% - 63px);
    height: 35px;
    margin-right: .2em;
    padding: .1em 0;
    border: 2px solid var(--bg2);
    border-radius: 5px;
    background-color: var(--bg2);
    display: flex;
    justify-content: space-between;
    align-items : center;

}

.left-pane label, .left-pane .collection, .left-pane .sort{
    cursor: pointer;
}

.search-container input{
    font-size: var(--header-size-secondary);
    width: calc(100% - 30px);
    height: 100%;
    border: none;
    background: transparent;
    padding: .5em;
}

.search-container label{
    width: 28px;
    text-align: center;
}

/* .left-pane-note-list-primary-container */
.collection-list-sec{
    max-height: 0;
    position: absolute;
    /* top: 100%; */
    right: .5em;
    overflow: hidden;
    background: var(--bg2); 
    z-index: 5;
    transition: max-height var(--main-transition) linear;

    overflow: scroll;
}
.collection-list-sec::-webkit-scrollbar{
    display: none;
}

.collection:hover .collection-list-sec {
    max-height: 60%;
}
.collection .drop-down-header{
    font-weight: var(--font-weight-heading);
    border-bottom: 2px solid var(--bg1);
    text-align: center;
    margin: .5em 0;
    cursor: default;
}


.collection .new-collection {
    font-weight: var(--font-weight-heading);
    border: 1px solid var(--fg2) !important;
}

.collection-list-sec p{
    color: var(--fg2);
    padding: 1.2em 1.5em;
    background-color: var(--bg2);
    transition: var(--main-transition);
}

.collection-list-sec .child:hover{
    border-top-right-radius: 50px;
    border-bottom-right-radius: 50px;
}

.collection-list-sec .child:hover {
    background-color: var(--bg1);
}

.search-and-notelist-parent{
    height: 100%;
}


.note-list-container-secondary{
    width: 100%;
    padding: .2em;
    padding: 0 0 1em;
    position: relative;
}

.add-note-btn{
    display: inline-block;
    width: 100%;
    height: 60px;
    font-size: large;
    color: var(--fg2);
    border:2px solid #444;
    margin-bottom: 1em;
    background-color: var(--bg2);
    transition: var(--main-transition);
    cursor: pointer;
    position: sticky;
    top: 0;
    z-index: 4;
}
.add-note-btn:hover{
    background-color: transparent;
}

.single-note{
    padding: 1em 0;
    border-bottom: 1px solid var(--bg2);
    transition: .3s;
    cursor: pointer;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;

    position: relative;
}

.single-note:hover{
    background-color: var(--bg2);
}

.single-note .note-title{
    font-size: var(--header-size-primary);
    font-weight: var(--font-weight-heading);
    margin-bottom: .5em;
}

.single-note .note-snippet{
    color : var(--fg2);
    width: 75%;
}

.single-note .updated-at{
    color: var(--fg2);
    position: absolute;
    right: 1em;
    top: 1em;
}

.single-note .note-options{
    font-size: var(--header-size-primary);
    border-radius: 50%;
    text-align: center;
    padding-top: .25em;
    width: 40px;
    height: 40px;
    line-height: 30px;
    
    display: none;

    position: absolute;
    top: 2.5em;
    right: 1.5em;

    transition: .3s;
}
@media (max-width:560px){
    .single-note .note-options{
        display: block;
    }
}

.single-note .note-options:hover{
    background-color: var(--bg1);
}

.single-note:hover .note-options{
    display: block;
}



.options-modal-center{
    width: 100%;
    max-height: 0;
    background-color: var(--bg2);
    overflow: hidden;

    position: sticky;
    bottom: 0;
    left: 0;
    z-index: 5;
    transition: max-height var(--main-transition) linear;
}

.close-note-options{
    font-size: 1.5rem;
    height: 50px;
    width: 50px;
    margin: auto;
    line-height: 50px;
    border: 2px solid var(--fg2);
    border-radius: 50%;
    text-align: center;
    margin-top: 1.5em;
    transition: var(--main-transition);
    cursor: pointer;
}
.close-note-options:hover{
    border-color: rgb(223, 51, 51);
}


@media (min-width : 250px){
    .options-modal-center{
        min-width: 157px;
    }
}

.options-modal-center .options-modal-child{
    cursor: pointer;
    font-weight: var(--font-weight-heading);
    padding: 1.2em 1.5em;
}

.options-modal-center .options-modal-child:hover{
    background-color: var(--bg1);
    border-top-right-radius: 50px;
    border-bottom-right-radius: 50px;
}

.options-modal-center .delete-note-option:hover {
    background-color: rgb(200, 0,0);
    cursor: pointer;
}

.add-to-coll{
    position: relative;
}
.collections-options{
    position: absolute;
    left: 11.5em;
    top: 70%;
    z-index: 6;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    gap: 1.5em;

    border-left: 2px solid #666;
    max-height: 0;
    width: 100%;
    overflow: hidden;
    transition: max-height linear .1s;
}
.add-to-coll:hover .collections-options {
    max-height: 400px;
}
.collections-options label{
    width: 100%;
    padding: 0 .5em;
}
.collections-options label:hover{
    border-bottom: 2px solid var(--bg1);
}

/* .left-pane-bottom-nav */
.left-pane-bottom-nav .note-btn, .left-pane-bottom-nav .todo-btn{
    font-weight: var(--font-weight-heading);
    height: 100%;
    width: 50%;
    color: var(--fg1);
    border: none;
    background: var(--bg2);
    transition: .3s;
    cursor: pointer;
}

.left-pane-bottom-nav .note-btn:hover,
.left-pane-bottom-nav .todo-btn:hover{
    background-color: var(--bg1);
}

.left-pane-bottom-nav .divider{
    height: 100%;
    width: 1px;
    background-color: #444;
}
/*------------------ LEFT PANE ENDS------------------------*/

/* ---------------RIGHT PANE ------------------*/
.right-pane{
    position: relative;
}
.right-pane-top-nav{
    width: 100%;
    height: 60px;
    max-height: 60px;
    padding: 0 2em;
    background: var(--bg2);

    display: flex;
    justify-content: space-between;
    align-items: center;
}
@media (min-width:576px){
    .right-pane-top-nav{
        padding: 0 4em;
    }
}
.note-inview-title{
    font-weight: var(--font-weight-heading);
    font-size: var(--header-size-primary);
    color: var(--fg1);
    background-color: transparent;
    border: none;
    border-bottom: 2px solid var(--bg1);
    padding: 0 1em;
    /* height: 80%; */
    padding-top: 1em;
    width: 90%;
}

.right-pane-top-nav .upload-pin{
    display: flex;
    justify-content: space-between;
    align-items: center;
    column-gap: 1em;
}
.upload-pin>span{
    cursor: pointer;
}



.view-pane{
    height: calc(100% - 120px);

}

.right-pane-bottom-nav{
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 60px;
    background-color: var(--bg2);
    padding: 0 2em;

    display: flex;
    justify-content: space-between;
    align-items: center;
}

@media (min-width:576px){
    .right-pane-bottom-nav{
        padding: 0 4em;
    }
}

.right-pane-bottom-nav .bottom-nav-left-options{
    display: flex;
    justify-content: space-between;
    align-items: center;
    column-gap: 1.5em;
}

.bottom-nav-left-options span{
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: .5em;
}

.right-pane-bottom-nav span{
    cursor: pointer;
}

.right-pane-bottom-nav .close{
    font-weight: var(--font-weight-regular);
    font-size: var(--header-size-primary);
}

.right-pane-view-layer2{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    background-color: var(--bg2);
    color: var(--fg2);
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 2em;
}
@media (max-width:576px){
    .right-pane-view-layer2{
        display: none;
    }
}
.right-pane-view-layer2 .view-layer2-header {
    font-size: 1.8rem;
    font-weight: 200;
}

/* .right-pane-view-layer1{
    display: none;
}
.right-pane-view-layer2{
    display: flex;
} */


/*---------------- RIGHT PANE ENDS  -------------------- */

/*---------------- LOGIN ---------------------*/
.signin-form-container{
    height: 100vh;
    width: 100%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.signin-form{
    width: 100%;
    padding: 1em 2em;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 3em;
}

.signin-form .form-input{
    width: 100%;
    max-width: 500px;
    display: inline-block;
    border-bottom: thin solid ;

    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: .5em;
}

.form-input input{
    width: calc(100% - 50px);
    height: 40px;
    color: #ddd;
    padding: 0 1em;
    border: none;
    background: transparent;
}

.signin-form .signin-btn{
    font-size: 1.5rem;
    width: 100%;
    max-width:250px;
    height: 50px;
}
/*---------------- LOGIN ENDS---------------------*/